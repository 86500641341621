export default {
  epod_terms_and_conditions: ` Morrison Express Corp. Ltd. and its affiliates and subsidiaries (collectively, "MEC") are delighted that you have visited our website.  We respect your privacy and it is important to us to protect your personal data during handling throughout the entire business process. We comply with international data protection legislation and in particular the EU General Data Protection Regulation (GDPR).  Our privacy practices may vary among the countries in which we operate to reflect local practices and legal requirements. To the extent permissible under the relevant laws, by using our products, services or website, you agree to the collection, use and sharing of your data in accordance with this Privacy Statement.  We reserve the right to change this Privacy Statement at any time, and will indicate the date the Privacy Statement was most recently updated.
  This privacy statement applies to the global cargo management platform of the Morrison Express Group, which you can access under the URL https://one.morrison.express and all sub-sites under this URL.. The controller responsible for processing your data pursuant to relevant laws such as Art. 4 No. 7 GDPR (where applicable) is the respective Morrison Express national company with which you have a contractual relationship or which is responsible for the services you have ordered or used.
  This website is operated on behalf of MEC by
  Morrison Express Corp. Ltd.
  Address: 7F, No. 360 Rueiguang Road, Taipei 114, Taiwan ROC 
  E-Mail: mec_onehelp@morrisonexpress.com
  Phone: +886 (2) 8752-6688
  Representative: Mr. Ed Wang 
  Contact details of Morrison Express national companies can be found at https://www.morrisonexpress.com/morrison-express-offices
  Depending on the functions in this website that you access to, we may collect the following information: 
  1.	Information that may identify you e.g. your name, address, email address, driving license details.
  2.	Information that you provide to us including 
    •	Business contact information, e.g. job title, department, company name, postal or e-mail address, telephone or fax number.
    •	Shipment information (such as contact information of shipper, consignee, notify party; description of goods, number of pieces, weight and value of shipment) that is provided for performance of a contract for service. 
    •	CCTV image, photo, personal signature
    •	Image of proof of delivery and/or cargo. 
  3.	Personal data collected automatically when you use our website, App or services, e.g. your internet protocol (IP) address, cookie identified, Country of origin information (from which country you are accessing our website), username and passwords you used to access our platform, mobile device unique identifier, SMS text, Social Media identifier (such as Facebook or Twitter ID), geographic location based on GPS/Wi-Fi/communications network local information.
  (Collectively referred to as “Data”)
  
  We collect your Data for the following purposes:
  1.	To collect and deliver shipments, perform the contract (or processing of the data is required for performing a procedure at the request of the customer prior to entering into a contract), provide products and/or services you request, including sending notifications for lawful reasons and purpose for the coordination and implementation of the services commissioned or used by you, according to Art. 6 (1) (b) GDPR (where applicable).
  2.	To improve our products and services, based on our legitimate interests according to relevant laws e.g. Art. 6 (1) (f) GDPR (where applicable).
  3.	To comply with laws, regulations, government policies and to protect the significant interest of MEC and its agents as well as parties relevant to any shipments agents including investigation and prevention of crime or unlawful activities, handling of claims and resolution of disputes etc., according to relevant laws e.g. Art. 6 (1) (c) GDPR (where applicable).
  4.	Use for other purposes about which we provide notice at the time of collection. 
  We collect your Data through fair and lawful means e.g. when you provide it to us directly, use our products or services etc.. We collect data directly from you, or automatically by your Web browser when you visit our website, or through the App that you have downloaded.
  For normal business operations, we may share your Data with the affiliated companies of MEC, third party service providers (e.g. carriers, custom brokers, warehouseman) and clients who are relevant to the performance of the service, conduct quality audits and due diligence. We may also share your Data with governmental agencies and regulators (e.g., tax authorities), external advisors (e.g., lawyers, accountants, and auditors), courts and other tribunals, and government authorities, to the extent required or permitted by applicable legal obligations.
  When you use our web services, applications software or website, we also collect personal data from you automatically through your web browser or the App that you have downloaded or via cookies and tracking beacons. All personal data that we collect from you in this way is automatically deleted after the purpose is no longer applicable and required by the relevant laws. Irrespective of this, we collect usage statistics about the use of our website in an anonymous form, which does not contain any information about you personally and cannot be traced back to you personally..
  We will retain your Data no longer than is necessary to carry out the purposes listed in this Privacy Statement and/or as required by applicable law. After expiry of the legal retention periods, we either anonymize or dispose of your Data using methods designed to prevent its loss, theft, misuse, or unauthorised access after disposal. As retention periods vary in different jurisdictions and depend on the laws applicable in your country. To obtain specific information about how long we store your data, please contact the Morrison Express national company responsible for your region or home country, which can be contacted under the contact details above or contact us at
  Morrison Express
  Legal and Risk Management Department
  Email: mhq_legal@morrisonexpress.com
  MEC operates on a global basis. Accordingly, we may transfer your Data outside of your country to its affiliated companies within Morrison Express group or to third party organizations for purposes connected with our services or MEC’s business. We may transfer the Data we collect about you to countries other than the country in which the personal data originally was collected. Those countries may not have the same privacy and data protection laws as the country in which you initially provided the information. When we transfer your Data to other countries, we will protect that information as described in this Privacy Statement and in accordance with applicable law. We use contractual protections for the transfer of personal data among various jurisdictions (including, e.g., the European Commission's Standard Contractual Clauses). The contractual text of the EU standard contractual clauses can be found at https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=CELEX%3A32010D0087
    As far as it is provided by and consistent with the local laws and regulations, you have the following rights in relation to your Data:
    1.	To request access to your Data.
    2.	To request that we rectify incorrect information about you.
    3.	In certain instances, you may have the right to erase your Personal Data, for example where the data is no longer needed for the purpose for which they were collected; where you have withdrawn consent and there is no other legal ground for processing; where you object to processing based on our legitimate interests, and MEC has no compelling reason to continue with such processing; where the processing was illegal; or where erasure is required by law to which Company is subject to.
    4.	In cases where the processing of your Data is based on Article 6 (1) (f) GDPR, including profiling based on those provisions, you have the right to object, on grounds relating to your particular situation, at any time. We may, in these cases, no longer process the personal data unless we can demonstrate compelling legitimate grounds for the processing which overrides your interests or for the establishment, exercise or defence of legal claims.
    5.	In certain instances consistent with laws and regulations, you may have the right to restrict processing of your Data, data portability of information you have provided to MEC, and to object to the processing of your personal data.
    6.	You may contact the competent supervisory authority and lodge complaints about the MEC’s data handling practices if you do not believe we have complied with the law.
  We have in place industry-accepted physical, technical and administrative security measures appropriate to our business to safeguard and secure the personal data we collect from you.
  If you would like to lodge a complaint or have any questions about this Privacy Statement, please contact us at: 
  Morrison Express
  Legal and Risk Management Department
  Email: mhq_legal@morrisonexpress.com 
  This website is intended to be used at the responsibility of the user.  MEC shall not be held liable for any and all damages incurred due to the use of various information obtained from this website or other website page to which a link is shown on this website.
  Effective since 1st Aug 2020
  
  `
}