export default {
  epod_terms_and_conditions: `鴻霖航空貨運代理股份有限公司及其子公司(以下稱鴻霖)非常榮幸地歡迎您進入我們的網站。我們十分重視您的個人隱私，同時，在整個貿易過程中保障您的個人資訊，對我們而言是非常重要的。我們遵循國際上與個人資料保護相關的立法，特別是歐盟的一般資料保護規範(GDPR)。為了符合當地法規的規範與實踐，我們的個資保護措施在不同國家間可能會有所不同。使用我們的產品、服務或網站時，在有關法律的許可範圍內，您同意我們依本聲明書蒐集、使用和分享您的個人資料。我們將保留在任何時間變更本聲明書的權利，並將標明本聲明書最新更新的日期。

  本聲明書適用於鴻霖全球運輸集團的全球貨物管理平台，您可透過此網址(https://one.morrison.express)進入該網站及所有子網站。根據有關法規，例如GDPR第四條第七項，負責處理您的資料的控管者為個別與您有契約關係或負責提供您所預定或使用服務的鴻霖全球運輸在各國的分公司、子公司。此網站由下列公司代表鴻霖營運： 
  鴻霖航空貨運代理股份有限公司
  地址: 台灣台北市內湖區瑞光路360號7樓
  信箱: mec_onehelp@morrisonexpress.com
  電話: +886 (2) 8752-6688
  代表: Mr. Ed Wang
  各鴻霖當地關係企業聯繫資料可在此取得 https://www.morrisonexpress.com/morrison-express-offices
  我們將根據您在該網站所使用的功能蒐集如下的資料:
  (個人資料類別: Ｃ○○一、Ｃ○三九、Ｃ○六一、Ｃ○九一、Ｃ○九二)
  1.	足以辨別您身分的資料。例如，您的姓名、地址、郵箱和汽車駕照內容
  2.	您所提供的資料，包括:
  •	商務聯絡資訊。例如，職稱、部門、公司名稱、地址或郵箱、電話或傳真號碼
  •	用以提供履行服務契約的貨物資料(例如，託運人、收貨人和被通知人的聯絡資料；貨物的明細、數量、重量及貨價)
  •	閉路電視影像、照片和個人簽名
  •	交貨證明和/或貨物的影像
  3.	當您在瀏覽我們的網站、使用應用程式或服務時，個人資料將自動地被蒐集。例如，您的IP位置、cookie標識、來源國的資訊(您從哪個國家/地區訪問我們的網站)、您用來登入我們平台的帳號和密碼、行動裝置單一識別碼、簡訊內容、社群媒體識別碼(例如臉書和推特的ID)、根據GPS/Wi-Fi/通信網路本地資訊所取得的地理位置。(統稱為「資料」)
  我們基於以下用途蒐集您的資料:
  1.	為收集和交付貨物、履行契約(或在與客戶締約前被要求處理資料以進行某程序)、提供您所要求的產品和/或服務，包括根據GDPR第六條第一項第B款(若適用)，出於合法原因和目的傳送通知,以便協調和實施您所委託或使用的服務。
  2.	為改進我們的產品和服務，基於有關法規，例如GDPR第六條第一項第F款(若適用)所賦予的合法權益。
  3.	為遵循法令、規範和政策以及根據GDPR第六條第一項第C款(若適用)，為保護鴻霖及其代理和與任何船務代理有關之一方的重要利益，包括調查和防止犯罪及非法活動、處理訴訟及爭端解決等。
  4.	用於其他於蒐集資訊時將一併通知之目的。
  我們透過公平合法的方式蒐集您的資料，例如，您直接向我們提供資料、使用我們產品或服務等。我們直接向您蒐集資料，或在您拜訪我們的網站，利用網站瀏覽器或透過您所下載的應用程式來自動蒐集。
  為正常商業運作，我們將會與鴻霖子公司、第三方服務提供者(例如，運送人、報關行、倉庫管理人¬)以及與服務績效、品管監督和盡職調查相關的客戶共享您的資料。我們也將在法律規範的要求或許可範圍內，與政府和監管機構(例如，稅務機關)、外部顧問(例如，律師、會計師和審計人員)、法院和其他法庭及其他政府部門共享您的資料。
  當您使用我們的Web服務、應用軟體或網站時，我們會透過您的網頁瀏覽器或您所下載的應用程式或利用cookie追蹤信號，自動蒐集個人資料。所有我們透過上述方式自動蒐集的個人資料將在使用目的消失或因應法律要求而刪除。無論如何，我們是以匿名的方式蒐集我們網站的使用數據，其中不包含您的任何個人訊息，也無法追蹤到您本人。
  我們保留您的資料的時間將不超過執行本聲明書所列目的所必要或有關法律所要求的時間。在法定保留期限屆至後，我們將對資料進行匿名化或透過避免處理後丟失、盜竊、誤用或未經授權使用的方法，來處理您的資料。上述保留期限因不同司法權而有所不同，端視您的國家所適用的法律。如欲知悉我們會保留您的資料多久的時間，請聯繫您所在地區或國家的鴻霖全球運輸集團的當地公司。您可以透過上述的聯繫資料向其取得聯繫或透過以下信箱與我們鴻霖全球運輸集團法務暨風險管理部取得聯繫。
  鴻霖全球運輸
  法務暨風險管理部
  信箱: mhq_legal@morrisonexpress.com.
  
  鴻霖在全球各地營運。因此，我們可能會將您的資料移轉出您所在的國家至鴻霖全球運輸集團所屬公司或第三方組織，用於與我們的服務或鴻霖業務相關的目的。我們可能會將蒐集到與您有關的資料移轉至最初蒐集到該筆資料以外的其他國家。這些國家可能欠缺與您原先所提供資料的國家相同的隱私權和資料保護法規。當我們將您的資料轉移到其他國家後，我們將按照本隱私權聲明及相關法律規定保護這些資訊。我們會在不同司法管轄權體系下，使用契約約定(包括例如歐盟委員會的標準合約條款)的保護方式來移轉個人資料。如欲參閱歐盟標準合約條款的條款內容請點選以下網址https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=CELEX%3A32010D0087
  只要當地法令規範有提供且與之相符合，您將就您的資料享有如下權利:
  1.	要求查詢或請求您的個人資料或提供複製本
  2.	要求更正有關於您的錯誤資料或對您的資料進行補充
  3.	要求停止蒐集、處理或利用您的個人資料，或請求刪除
  4.	如該處理係依據GDPR第6條第1項第F款，包括根據其他條款進行建檔，您在任何時間都有權基於與您具體情況有關之理由拒絕。在這些情況下，我們將不再繼續處理這些個人資料直到我們足以證明有優先於您權利之法律依據、或為建立、 行使或防禦法律上請求所為之者。
  5.	在符合法規範的特定情況下，您將有權限制與您有關的資料處理、您提供給鴻霖資料得的可攜性以及拒絕鴻霖處理您的個人資料。
  6.	若您不相信我們遵守法律，您可以聯繫主管監督機關並申訴鴻霖的資訊處理。
  我們已經設立業界能接受的物理性、技術性和行政性的安全措施，以確保向您收集的個人資料的安全性。
  如果您欲申訴或對本隱私權聲明書有任何疑問，請與我們聯繫。
  鴻霖全球運輸
  法務暨風險管理部
  信箱: mhq_legal@morrisonexpress.com.
  使用者應承擔使用本網站所生的責任。鴻霖對於因使用本網站或本網站所顯示的其他網站連結所獲取各種資訊而受之任何和全部損失不負擔任何責任。
  您有權自由選擇是否提供個人資料，然若您不同意上述關於資料的蒐集、處理與利用，您將無權繼續使用本網站。
  
  生效日期: 2020年8月1日  
      `
}