import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/lib/integration/react'

import config from './config'
import store, { persistor } from './configureStore'

import './assets/fonts/datta/datta-icons.css'
import './assets/fonts/feather/icon-font.css'
import './assets/fonts/fontawesome/css/all.min.css'
import ErrorBoundary from './App/commons/components/ErrorBoundary'
import FullPageRoutes from './App/FullPageRoutes'
import LoadingOverlayWapper from './App/commons/components/LoadingOverlayWapper'
const app = (
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter basename={config.basename}>
          <LoadingOverlayWapper>
            <FullPageRoutes />
          </LoadingOverlayWapper>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </ErrorBoundary>
)

if (config.sentry_env === 'prod') {
  Sentry.init({
    dsn: config.sentry_dsn,
    environment: config.sentry_env,
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      }),
    ],
    ignoreErrors: ['Network Error', /Object Not Found Matching Id:/],
    tracesSampleRate: 0.3,
    replaysOnErrorSampleRate: 1.0,
  })
}

ReactDOM.render(app, document.getElementById('root'))
